import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { document, isServer } from '../Shared/Scripts/ssrbase';
import languages from '../../../Assets/locales/languages.json';

// Fix for preventing typescript to complain about possible null returns from t('key') function
declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

let lang = 'no';
if (isServer) {
  // Change Language is triggered from the backend javascript engine before using a engine
  // Look at app.UseReact -> AddGetEngineCallback in Startup.cs
} else {
  lang = document.documentElement?.lang;
}

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: 'no',
  lng: lang,
  returnNull: false,
  interpolation: {
    escapeValue: true,
  },
  resources: {
    ...languages,
  },
  react: {
    useSuspense: false,
  },
});

export const ChangeLanguage = (code: string) => {
  i18n.changeLanguage(code);
};
