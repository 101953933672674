/* *********************************************************************************** */
/* This file is a plain JS-file to prevent dealing with types on the globalThis object */
/* *********************************************************************************** */

// Import react dependencies
import * as ReactCustom from 'react';
import * as ReactDOMCustom from 'react-dom';
import * as ReactDOMClientCustom from 'react-dom/client';
import * as ReactDOMServerCustom from '~/react-dom/cjs/react-dom-server-legacy.browser.production.min.js';
import { ChangeLanguage } from './Language/i18nInit';

// Import all react components
import components from './components';

// Import base css file in order for css to get built
import './index.css';

// Expose react dependencies as global variables
globalThis.React = ReactCustom;
globalThis.ReactDOM = ReactDOMCustom;
globalThis.ReactDOMClient = ReactDOMClientCustom;
globalThis.ReactDOMServer = ReactDOMServerCustom;

// Expose react components as a global variable
globalThis.ReactComponents = components;

// Expose change language function
globalThis.ChangeLanguage = ChangeLanguage;
