import * as React from 'react';
import { MainMenuModels } from './MainMenuModels';
import MainMenuIconProductlist from './MainMenuIconProductlist';
import MainMenuLanguageSelector from './MainMenuLanguageSelector';
import MainMenuWeather from './MainMenuWeather';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const MainMenu: FC<MainMenuModels> = (props) => {
  const { t } = useTranslation();
  const [megamenuVisible, setMegamenuVisible] = React.useState<boolean>(false);
  const [megamenuStyle, setMegamenuStyle] = React.useState({ height: '600px' });
  const [searchVisible, setSearchVisible] = React.useState(false);
  const [visibleFolder, setVisibleFolder] = React.useState('');
  const [visibleFolderName, setVisibleFolderName] = React.useState('');
  const [transitionClass, setTransitionClass] = React.useState(false);
  const [themeColor, setThemeColor] = React.useState<string>('white');
  const inputRef = React.useRef<HTMLInputElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);

  const setFolder = (el: string) => {
    setVisibleFolder(trimeFolderName(el));
    setVisibleFolderName(el);
  };

  const trimeFolderName = (el: string) => {
    return el.replace(/\s+/g, '').toLowerCase();
  };

  const isVisible = (el: string) => {
    if (trimeFolderName(el) === visibleFolder) {
      return true;
    }
    return false;
  };

  const preventBodyScroll = () => {
    if (megamenuVisible !== true && searchVisible !== true) {
      document.body.classList.add('overflow-hidden', 'h-full');
    } else {
      document.body.classList.remove('overflow-hidden', 'h-full');
    }
  };

  const toggleVisible = (el: string) => {
    if (el === 'search') {
      if (megamenuVisible === true) {
        setTransitionClass(false);
      } else {
        setTransitionClass(true);
      }
      setMegamenuVisible(false);
      setSearchVisible(!searchVisible);
      preventBodyScroll();
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }

    if (el === 'menu') {
      if (searchVisible === true) {
        setTransitionClass(false);
      } else {
        setTransitionClass(true);
      }
      setSearchVisible(false);
      setMegamenuVisible(!megamenuVisible);
      preventBodyScroll();
    }
  };

  React.useEffect(() => {
    if (headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      setMegamenuStyle({ height: `calc(100vh - ${headerHeight}px)` });
    }
  }, [megamenuVisible]);

  React.useEffect(() => {
    if (
      props.mainMenuModel.themeColor !== '' &&
      props.mainMenuModel.themeColor !== null
    ) {
      const color = props.mainMenuModel.themeColor;
      setThemeColor(color.toLowerCase());
    }
  }, []);

  return (
    <>
      <div
        className={`py-4 transition-all
          ${
            megamenuVisible || searchVisible
              ? 'bg-black-light text-white'
              : `border-b text-black ${
                  themeColor === 'white'
                    ? 'border-gray-80 bg-white'
                    : `bg-${themeColor} border-${themeColor}-dark`
                }`
          }
        `}
        ref={headerRef}
      >
        <div className="container px-4">
          <div className="flex items-center justify-between">
            <div
              className={`w-full max-w-[70px] tablet:max-w-[82px] transition-all ${
                megamenuVisible || searchVisible
                  ? 'desktop:max-w-[88px] pt-1.5'
                  : 'desktop:max-w-[106px]'
              } `}
            >
              <a href="/" title="Trysil.com">
                <svg
                  id="logo"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 118 40"
                  className={`transition-all ${
                    megamenuVisible || searchVisible
                      ? 'fill-white'
                      : 'fill-black'
                  }`}
                >
                  <path d="M20.6532 0H26.6638C27.976 4.95238 25.2246 9.26984 18.7484 9.26984C17.9019 9.26984 17.1823 9.22751 16.5474 9.14286V23.4921C16.5474 25.2698 17.2246 26.2011 18.9601 26.2011C20.1453 26.2011 20.9495 25.8624 22.0077 25.2698V30.6878C20.9918 31.2381 19.595 31.5767 17.8596 31.5767C11.1294 31.5767 10.4098 26.1587 10.4098 23.4921V7.78836C9.77489 7.66138 9.09764 7.61905 8.29341 7.61905C6.38865 7.61905 5.33045 9.3545 6.38865 11.5556H0.378066C-1.10342 6.81482 1.81722 2.32804 8.29341 2.32804C12.9495 2.32804 15.1506 3.97884 18.7484 3.97884C20.9918 3.97884 21.5421 2.49735 20.6532 0ZM25.9019 9.77778H31.7008V13.2487C32.8013 10.6667 34.8754 9.14286 37.5844 9.14286C41.14 9.14286 43.4257 11.7672 43.4257 15.7037C43.4257 17.3968 43.0024 19.1323 42.3252 20.5714H36.3569C37.1188 19.2169 37.5421 17.9894 37.5421 16.8466C37.5421 15.1958 36.6532 14.1376 35.2564 14.1376C33.1823 14.1376 31.7008 16.6349 31.7008 20.4868V30.9418H25.9019V9.77778ZM45.6268 39.3651V34.4974C46.7273 34.8783 47.7008 35.09 48.5474 35.09C50.9601 35.09 52.1453 33.5661 52.8225 32.0847L44.2299 9.77778H50.3252L55.3622 25.1852L59.976 9.77778H65.9865L58.2828 32.5079C57.394 35.0899 55.3622 40 49.1823 40C48.0818 40 46.8543 39.8307 45.6268 39.3651Z" />
                  <path d="M87.9548 22.9418C87.9548 27.9788 84.3992 31.5767 78.0924 31.5767C75.7643 31.5767 73.5633 30.8995 71.6585 29.6296L70.8119 30.9418H64.6744C65.8172 29.3333 66.8331 27.5556 67.6797 25.6508C66.4945 23.9577 65.6056 21.9259 64.9707 19.6402L69.1188 17.8201C69.3305 18.5397 69.5421 19.2593 69.7961 19.8942C70.8543 16.4233 71.4892 12.8677 71.8278 9.77779H78.6003C81.4363 14.0529 87.9548 17.3545 87.9548 22.9418ZM78.0924 26.6667C80.886 26.6667 82.1135 25.1852 82.1135 22.9418C82.1135 19.7249 79.1929 18.6667 76.2299 15.3228C75.6373 18.6667 74.7908 22.1799 73.6479 25.2275C74.9601 26.1587 76.4416 26.6667 78.0924 26.6667Z" />
                  <path d="M90.7484 21.3757L91.722 9.77778H97.0976L96.1241 21.3757C95.7855 25.3968 97.0976 26.6243 99.3833 26.6243C100.992 26.6243 102.685 25.8201 104.251 24.5079C102.346 19.9788 101.542 14.0106 101.542 8.88889C101.542 3.76719 104.293 0.211639 109.373 0.211639C114.96 0.211639 117.288 4.48677 117.288 9.39682C117.288 14.3069 114.706 20.5714 110.727 25.2275C111.531 26.0741 112.505 26.6243 113.69 26.6243C114.791 26.6243 116.018 26.2434 116.949 25.6931V30.6032C116.018 31.1534 114.494 31.5767 112.463 31.5767C110.177 31.5767 108.315 30.5185 106.833 28.8254C104.463 30.5185 101.838 31.5767 99.1294 31.5767C93.2458 31.5767 90.2405 27.3439 90.7484 21.3757ZM91.468 3.64021C91.468 1.69312 92.9495 0.211639 94.8966 0.211639C96.8437 0.211639 98.3251 1.69312 98.3251 3.64021C98.3251 5.5873 96.8437 7.06878 94.8966 7.06878C92.9495 7.06878 91.468 5.5873 91.468 3.64021ZM108.272 19.5979C110.092 16.5079 111.32 12.7831 111.32 9.43915C111.32 6.51852 110.6 5.16402 109.373 5.16402C108.315 5.16402 107.299 6.01058 107.299 8.88889C107.299 11.3862 107.426 15.8307 108.272 19.5979Z" />
                </svg>
              </a>
            </div>

            <ul className="flex items-center gap-4 tablet:gap-7">
              {props.mainMenuModel.availableLanguages.length > 1 && (
                <li className="hidden tablet:block">
                  <MainMenuLanguageSelector
                    currentLocalization={props.currentCulture}
                    localizations={props.mainMenuModel.availableLanguages}
                    megamenuvisible={megamenuVisible || searchVisible}
                  />
                </li>
              )}

              <li>
                <button
                  title={t('menu/search')}
                  className=" text-base leading-4 font-medium group"
                  onClick={() => {
                    toggleVisible('search');
                  }}
                >
                  {searchVisible ? (
                    <span className="flex items-center gap-1">
                      <span className="group-hover:underline">
                        {t('menu/close')}
                      </span>
                      <span
                        className="material-symbols-outlined"
                        aria-hidden="true"
                      >
                        search_off
                      </span>
                    </span>
                  ) : (
                    <span className="flex items-center gap-1">
                      <span className="group-hover:underline">
                        {t('menu/search')}
                      </span>
                      <span
                        className="material-symbols-outlined"
                        aria-hidden="true"
                      >
                        search
                      </span>
                    </span>
                  )}
                </button>
              </li>

              <li>
                <button
                  type="button"
                  className=" text-base leading-4 font-medium group"
                  onClick={() => {
                    toggleVisible('menu');
                  }}
                >
                  {megamenuVisible ? (
                    <span className="flex items-center gap-1">
                      <span className="group-hover:underline">
                        {t('menu/close')}
                      </span>
                      <span
                        className="material-symbols-outlined"
                        aria-hidden="true"
                      >
                        close
                      </span>
                    </span>
                  ) : (
                    <span className="flex items-center gap-1">
                      <span className="group-hover:underline">
                        {t('menu/menu')}
                      </span>
                      <span
                        className="material-symbols-outlined"
                        aria-hidden="true"
                      >
                        menu
                      </span>
                    </span>
                  )}
                </button>
              </li>
            </ul>
          </div>

          {/* Productlist header */}
          <ul
            className={` items-top gap-8 mt-3 ${
              megamenuVisible || searchVisible
                ? 'hidden desktop:hidden'
                : 'hidden desktop:flex'
            }`}
          >
            {props.mainMenuModel.productListLinks.map(
              (item: any, index: React.Key | null | undefined) => {
                return (
                  <li key={index}>
                    <a
                      href={item.url}
                      title={item.text}
                      className="flex items-center gap-2 text-base font-semibold text-black group"
                    >
                      <MainMenuIconProductlist
                        productListType={item.productListType}
                        cssClass="w-6 h-6"
                      />
                      <span className="group-hover:underline">{item.text}</span>
                    </a>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </div>

      {/* Megamenu */}
      <nav
        style={megamenuStyle}
        className={`relative text-white ${
          transitionClass ? 'transition' : ''
        } bg-[url('/svg/bg-menu.svg')] bg-no-repeat bg-left-bottom  ${
          megamenuVisible
            ? 'bg-black-light h-dvh max-h-dvh overflow-y-auto -mt-[1px]'
            : 'bg-white max-h-0 overflow-hidden'
        }`}
      >
        <div className="absolute inset-0 flex flex-col justify-between">
          <div className="mx-auto w-full desktop:max-w-[948px] px-4 desktop:px-0 flex flex-col tablet:flex-row gap-7 mb-10 desktop:mb-0">
            {/* Language selector mobile */}
            {props.mainMenuModel.availableLanguages.length > 1 && (
              <div
                className={`tablet:hidden flex flex-row items-center justify-between ${
                  visibleFolder !== '' ? 'opacity-0 tablet:opacity-100' : ''
                }`}
              >
                <div className="flex items-center gap-2.5 font-bold text-2xl">
                  <span
                    className="material-symbols-outlined"
                    aria-hidden="true"
                  >
                    globe
                  </span>
                  <span className="uppercase">{props.currentCulture}</span>
                </div>

                <button
                  type="button"
                  className="flex items-center group gap-2.5 text-xl"
                  onClick={() => {
                    setFolder(t('menu/selectlangugage'));
                  }}
                >
                  <span className="group-hover:underline">
                    {t('menu/selectlangugage')}
                  </span>
                  <span
                    className="material-symbols-outlined"
                    aria-hidden="true"
                  >
                    chevron_right
                  </span>
                </button>
              </div>
            )}
            {/* Productlist megamenu */}
            <ul
              className={`flex flex-col gap-3.5 w-full tablet:w-1/2 desktop:w-1/3 tablet:border-r tablet:border-gray-60 transition-all ${
                visibleFolder !== '' ? 'opacity-0 tablet:opacity-100' : ''
              }`}
            >
              {props.mainMenuModel.productListLinks.map(
                (item: any, index: React.Key | null | undefined) => {
                  return (
                    <li key={index}>
                      <a
                        href={item.url}
                        title={item.text}
                        className="flex items-center gap-2.5 text-2xl font-bold text-white hover:text-purple active:text-purple-dark group focus-visible:outline focus-visible:outline-2 focus-visible:outline-white rounded p-1.5"
                      >
                        <MainMenuIconProductlist
                          productListType={item.productListType}
                          cssClass="w-6 h-6"
                        />
                        <span className="group-hover:underline decoration-solid decoration-1 underline-offset-2">
                          {item.text}
                        </span>
                      </a>
                    </li>
                  );
                }
              )}
            </ul>
            {/* Col 2 */}
            <div
              className={`w-full tablet:w-1/2 desktop:w-1/3 desktop:border-r desktop:tablet:border-gray-60 transition-all ${
                visibleFolder !== ''
                  ? 'opacity-0 tablet:hidden desktop:block desktop:opacity-100'
                  : 'opacity-100 block'
              }`}
            >
              <ul className="flex flex-col gap-2.5">
                {props.mainMenuModel.menuItem1.title !== null && (
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        setFolder(props.mainMenuModel.menuItem1.title);
                      }}
                      className={`w-full desktop:px-7 py-2.5 flex items-center justify-between text-white hover:text-purple active:text-purple-dark text-xl group ${
                        isVisible(props.mainMenuModel.menuItem1.title)
                          ? 'bg-gray-30'
                          : ''
                      }`}
                    >
                      <span className="group-hover:underline">
                        {props.mainMenuModel.menuItem1.title}
                      </span>
                      <span
                        className="material-symbols-outlined"
                        aria-hidden="true"
                      >
                        chevron_right
                      </span>
                    </button>
                  </li>
                )}

                {props.mainMenuModel.menuItem2.title !== null && (
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        setFolder(props.mainMenuModel.menuItem2.title);
                      }}
                      className={`w-full desktop:px-7 py-2.5 flex items-center justify-between text-white hover:text-purple active:text-purple-dark text-xl group ${
                        isVisible(props.mainMenuModel.menuItem2.title)
                          ? 'bg-gray-30'
                          : ''
                      }`}
                    >
                      <span className="group-hover:underline">
                        {props.mainMenuModel.menuItem2.title}
                      </span>
                      <span
                        className="material-symbols-outlined"
                        aria-hidden="true"
                      >
                        chevron_right
                      </span>
                    </button>
                  </li>
                )}

                {props.mainMenuModel.newsList.url !== '' && (
                  <li>
                    <a
                      href={props.mainMenuModel.newsList.url}
                      title={props.mainMenuModel.newsList.text}
                      className="desktop:px-7 py-2.5 text-white hover:text-purple active:text-purple-dark text-xl inline-block hover:underline"
                    >
                      {props.mainMenuModel.newsList.text}
                    </a>
                  </li>
                )}
              </ul>
            </div>
            {/* Col 3 */}
            <div
              className={`h-full flex-col gap-3.5 w-full tablet:w-1/2 desktop:w-1/3 p-4 tablet:p-0 transition-all duration-500 absolute inset-0 z-40 tablet:relative desktop:flex ${
                visibleFolder !== '' ? '' : 'hidden'
              }`}
            >
              <button
                type="button"
                onClick={() => setVisibleFolder('')}
                className="desktop:hidden font-bold text-xl hover:text-purple active:text-purple-dark hover:underline flex items-center gap-2.5 mb-7"
              >
                <span className="material-symbols-outlined" aria-hidden="true">
                  chevron_left
                </span>
                <span>{visibleFolderName}</span>
              </button>

              {props.mainMenuModel.menuItem1.subItems.length > 0 && (
                <ul
                  className={`flex flex-col gap-7 transition-opacity duration-500 ${
                    isVisible(props.mainMenuModel.menuItem1.title)
                      ? 'relative opacity-100'
                      : 'absolute opacity-0 inset-0 -z-10'
                  }`}
                >
                  {props.mainMenuModel.menuItem1.subItems.map(
                    (item: any, index: React.Key | null | undefined) => {
                      return (
                        <li key={index}>
                          <a
                            href={item.url}
                            title={item.text}
                            className=" text-xl hover:text-purple active:text-purple-dark hover:underline"
                          >
                            {item.text}
                          </a>
                        </li>
                      );
                    }
                  )}
                </ul>
              )}

              {props.mainMenuModel.menuItem2.subItems.length > 0 && (
                <ul
                  className={`flex flex-col gap-7 transition-opacity duration-500 ${
                    isVisible(props.mainMenuModel.menuItem2.title)
                      ? 'relative opacity-100'
                      : 'absolute opacity-0 inset-0 -z-10'
                  }`}
                >
                  {props.mainMenuModel.menuItem2.subItems.map(
                    (item: any, index: React.Key | null | undefined) => {
                      return (
                        <li key={index}>
                          <a
                            href={item.url}
                            title={item.text}
                            className=" text-xl hover:text-purple active:text-purple-dark hover:underline"
                          >
                            {item.text}
                          </a>
                        </li>
                      );
                    }
                  )}
                </ul>
              )}

              {props.mainMenuModel.availableLanguages.length > 0 && (
                <ul
                  className={`flex flex-col gap-7 transition-opacity duration-500 ${
                    isVisible(t('menu/selectlangugage'))
                      ? 'relative opacity-100'
                      : 'absolute opacity-0 inset-0 -z-10'
                  }`}
                >
                  {props.mainMenuModel.availableLanguages.map(
                    (
                      item: { code: string; name: string },
                      index: React.Key | null | undefined
                    ) => {
                      if (item.code !== undefined) {
                        return (
                          <li key={index}>
                            <a
                              href={`/${item.code}/`}
                              title={item.name}
                              className="text-xl capitalize hover:underline focus:outline"
                            >
                              {item.name}
                            </a>
                          </li>
                        );
                      }
                      return false;
                    }
                  )}
                </ul>
              )}
            </div>
          </div>

          {/* Weather */}
          <div className={visibleFolder !== '' ? 'hidden tablet:block' : ''}>
            <MainMenuWeather weatherInfo={props.mainMenuModel.weatherInfo} />
          </div>
        </div>
      </nav>

      {/* Search */}
      <div
        className={`relative text-white ${
          transitionClass ? 'transition' : ''
        } bg-[url('/svg/bg-menu.svg')] bg-no-repeat bg-left-bottom  ${
          searchVisible
            ? 'bg-black-light h-dvh max-h-dvh overflow-y-auto -mt-[1px] '
            : 'bg-white max-h-0 overflow-hidden'
        }`}
      >
        <form className="mx-auto w-full max-w-[792px] px-4" action="/sok/">
          <fieldset>
            <legend className=" text-2xl mb-2.5 text-center">
              {t('menu/searchlegend')}
            </legend>
            <div className="flex flex-row">
              <input
                type="search"
                name="q"
                className="w-full max-w-[660px] pl-5 pr-2.5 leading-[50px] text-xl rounded-l-md text-black"
                placeholder={t('menu/searchplaceholder')}
                ref={inputRef}
              />
              <button
                type="submit"
                className=" px-7 bg-purple group active:bg-purple-dark text-black text-xl flex items-center gap-1 rounded-r-md "
              >
                <span className="hidden desktop:block group-hover:underline">
                  {t('menu/search')}
                </span>
                <span className="material-symbols-outlined" aria-hidden="true">
                  search
                </span>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </>
  );
};
export default MainMenu;
