import * as React from 'react';
import { FC } from 'react';
import SearcPageEditorBox from './SearchPageEditorBox';
import { useTranslation } from 'react-i18next';

interface Page {
  pageTitle: string;
  url: string;
  ingress: string;
  pageType: string;
}

interface Results {
  totalMatching: number;
  success: boolean;
  lastPage: boolean;
  page: number;
  pageSize: number;
  pageCount: number;
  pages: Page[] | [];
}

interface editorboxes {
  box1: {
    title: string;
    subtitle: string;
    url: string;
  };
  box2: {
    title: string;
    subtitle: string;
    url: string;
  };
  box3: {
    title: string;
    subtitle: string;
    url: string;
  };
}

const SearchPage: FC<editorboxes> = (props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [searchResults, setSearchResults] = React.useState<Results>();
  const [searchPageSize, setSearchPageSize] = React.useState<number>(24);
  const [searchError, setSearchError] = React.useState(false);
  const [searchResultsPages, setSearchResultsPages] = React.useState<Page[]>(
    []
  );
  const [optionPage, setOptionPage] = React.useState<number>(1);
  const [ignore, setIgnore] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [searched, setSearched] = React.useState(false);

  const handleChange = (
    e: { target: { value: React.SetStateAction<string> } } | undefined
  ) => {
    if (e !== undefined) {
      setIgnore(true);
      setSearchValue(e.target.value);
      setOptionPage(1);
    }
  };

  const search = async () => {
    if (searchValue !== '') {
      setIgnore(true);
      setLoading(true);
      setSearched(true);
      const url = `/api/global/search?q=${searchValue}&page=${optionPage}&pagesize=${searchPageSize}`;

      try {
        const response = await fetch(url);
        const data = await response.json();
        setIgnore(false);
        setLoading(false);
        return data;
      } catch (error) {
        console.log(error);
        setSearchError(true);
      }
    }
  };

  const updateUrl = (q: string, page: number) => {
    // Update parameter in URL
    const url = new URL(
      location.protocol + '//' + location.host + location.pathname
    );
    url.searchParams.set('q', q);
    url.searchParams.set('page', page.toString());
    history.pushState({}, '', url);
  };

  const handleSubmit = async (form: { preventDefault: () => void }) => {
    form.preventDefault();

    // Reset prev. searches
    setSearchResults(undefined);
    setSearchResultsPages([]);
    setOptionPage(1);
    setSearchPageSize(24);

    // Set search
    const data = await search();
    setSearchResultsPages(data.pages);
    setSearchResults(data);
    setOptionPage(1);

    // Set URL
    updateUrl(searchValue, 1);
  };

  const handleMore = async () => {
    setSearchPageSize(24);

    const data = await search();

    // Merge existing resultst with new results
    const existingResults = [...(searchResultsPages || [])];
    const newResults = [...data.pages];
    const combineResults = [...existingResults, ...newResults];

    // Update states
    setSearchResultsPages(combineResults);
    setSearchResults(data);
    setOptionPage((prev: number) => {
      return prev + 1;
    });

    // Update URL
    updateUrl(searchValue, Number(optionPage) + 1);
  };

  const init = async () => {
    const params = new URLSearchParams(document.location.search);
    const q = params.get('q');
    const page = params.get('page');
    let pagesize = searchPageSize;

    if (page !== null) {
      pagesize = 24 * parseInt(page, 10);
    }

    if (q !== '' && q !== null) {
      // Reset prev. searches
      setSearchResults(undefined);
      setSearchResultsPages([]);

      // Set new values
      setSearchValue(q);
      setSearchPageSize(pagesize);

      // Performe search
      if (searchValue === q) {
        const data = await search();
        setSearchResultsPages(data.pages);
        setSearchResults(data);
        setOptionPage((prev: number) => {
          return prev + 1;
        });
      }
    }
  };

  React.useEffect(() => {
    if (!ignore) {
      init();
    }
  }, [searchValue]);

  return (
    <>
      <div className="container px-4 mt-10 max-w-[680px]">
        <h1 className="text-center font-display text-5xl font-bold mb-10">
          {searched && t('searchpage/header')}
          {!searched && t('searchpage/search')}
        </h1>

        {typeof searchResultsPages !== 'undefined' &&
          searchResultsPages?.length > 0 && (
            <p className="-mt-7 mb-10  text-2xl text-center">
              {t('searchpage/show')} {searchResultsPages?.length}{' '}
              {t('searchpage/hits')}
            </p>
          )}

        {searchResultsPages?.length === 0 && searched && (
          <p className="-mt-7 mb-10  text-2xl text-center">
            {t('searchpage/nohits')}
          </p>
        )}

        <form onSubmit={handleSubmit} className="mb-20">
          <fieldset>
            <legend className="sr-only">{t('searchpage/label')}</legend>
            <div className="flex flex-row">
              <label htmlFor="searchform" className="sr-only">
                {t('searchpage/search')}
              </label>
              <input
                id="searchform"
                type="search"
                value={searchValue}
                onChange={handleChange}
                className="bg-white border-black border border-r-0 rounded-l-md text-xl px-5 py-2.5 w-full "
              />
              <button
                type="submit"
                disabled={loading}
                className="text-xl px-7 flex flex-row items-center gap-1 border border-black border-l-purple bg-purple rounded-r-md hover:bg-purple-dark hover:border-l-purple-dark active:bg-purple-light active:border-l-purple-light"
              >
                <span className="sr-only tablet:not-sr-only">
                  {t('searchpage/search')}
                </span>
                <span className="material-symbols-outlined" aria-hidden="true">
                  search
                </span>
              </button>
            </div>
          </fieldset>
        </form>

        {searchError === true && (
          <div className="text-center bg-purple text-black p-4 mb-10 rounded">
            <h2 className=" text-2xl font-bold">En feil oppsto</h2>
            <p>Prøv på nytt. Hvis feilen vedvarer så ta kontakt med oss.</p>
          </div>
        )}

        {typeof searchResultsPages !== 'undefined' &&
          searchResultsPages.length > 0 && (
            <ul className="flex flex-col gap-7 mb-16">
              {searchResultsPages.map((item, index) => {
                return (
                  <li key={index}>
                    <h3 className=" text-2xl font-bold">
                      <a
                        href={item.url}
                        title={item.pageTitle}
                        className="hover:underline"
                      >
                        {item.pageTitle}
                      </a>
                    </h3>
                    {item.ingress !== '' && (
                      <p className=" mt-1 text-base">{item.ingress}</p>
                    )}
                  </li>
                );
              })}
            </ul>
          )}

        {loading && (
          <div className=" text-center text-9xl text-black-light">
            <span
              className="material-symbols-outlined animate-spin"
              aria-hidden="true"
            >
              progress_activity
            </span>
          </div>
        )}

        {typeof searchResultsPages !== 'undefined' &&
          searchResultsPages?.length > 0 && (
            <div className="text-center pb-10">
              <p className="text-base text-center mb-2.5">
                {t('searchpage/show')} {searchResultsPages?.length}{' '}
                {t('searchpage/hits')}
              </p>

              {typeof searchResultsPages !== 'undefined' &&
                searchResults?.lastPage !== true && (
                  <button
                    type="button"
                    className="btn-default btn-default-black"
                    onClick={() => handleMore()}
                  >
                    {t('searchpage/more')}
                  </button>
                )}
            </div>
          )}
      </div>

      {typeof searchResultsPages !== 'undefined' &&
        searchResultsPages?.length === 0 &&
        !loading && (
          <div className="container max-w-[1194px] px-4">
            <h2 className="mb-10 font-bold text-2xl text-center">
              {t('searchpage/again')}
            </h2>

            <div className="flex flex-col tablet:flex-row gap-7 items-center justify-center">
              {props.box1.title !== '' && (
                <SearcPageEditorBox
                  title={props.box1.title}
                  subtitle={props.box1.subtitle}
                  url={props.box1.url}
                />
              )}

              {props.box2.title !== '' && (
                <SearcPageEditorBox
                  title={props.box2.title}
                  subtitle={props.box2.subtitle}
                  url={props.box2.url}
                />
              )}

              {props.box3.title !== '' && (
                <SearcPageEditorBox
                  title={props.box3.title}
                  subtitle={props.box3.subtitle}
                  url={props.box3.url}
                />
              )}
            </div>
          </div>
        )}
    </>
  );
};

export default SearchPage;
