import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import NewsListCard from './NewsListCard';
import Loader from '@/Global/Scripts/Loader/Loader';

interface Data {
  newsCategoryFacets: [];
  news: [];
  totalMatching?: number;
}

const NewsListPage: FC = () => {
  const { t } = useTranslation();

  const [data, setData] = React.useState<Data>({
    newsCategoryFacets: [],
    news: [],
  });
  const [selectedCategories, setSelectedCategories] = React.useState<string[]>(
    []
  );
  const [pageSize, setPageSize] = React.useState(12);
  const [initial, setInitial] = React.useState(true);
  const [showMobileFilter, setShowMobileFilter] = React.useState(false);

  // Toggle category
  const handleToggleCategory = (id: string) => {
    if (!selectedCategories.includes(id)) {
      setSelectedCategories((prevStateArray) => [...prevStateArray, id]);
    } else {
      const newArr = [...selectedCategories];

      newArr.filter((value, index, arr) => {
        if (value === id) {
          arr.splice(index, 1);
          return true;
        }
        return false;
      });

      setSelectedCategories(newArr);
    }
  };

  // Update URL
  const updateUrl = (parameters: string) => {
    // Update parameter in URL
    const url = new URL(
      location.protocol + '//' + location.host + location.pathname
    );

    history.replaceState({}, '', `${url}?${parameters}`);
  };

  // Set states based on URL parameters
  const setStateByUrlParams = () => {
    const params = new URLSearchParams(document.location.search);
    const count = params.get('count');
    let categories = params.get('Categories');

    if (categories === null) {
      categories = params.get('categories');
    }

    // Set count
    if (count !== '' && count !== null) {
      setPageSize(Number(count));
    }

    // Set categories
    if (typeof categories === 'string' && categories !== null) {
      setSelectedCategories(categories.split(',').map(String));
    }
  };

  // Search
  const search = async () => {
    let categories = '';
    if (selectedCategories.length > 0) {
      categories = `&Categories=${selectedCategories}`;
    }

    const url = `/api/news/search?count=${pageSize}${categories}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
      updateUrl(`count=${pageSize}${categories}`);
    } catch (error) {
      console.log(error);
    }
  };

  // Load more
  const loadMore = () => {
    setPageSize(Number(pageSize) + 12);
  };

  React.useEffect(() => {
    search();
  }, [selectedCategories, pageSize]);

  React.useEffect(() => {
    const params = new URLSearchParams(document.location.search);
    if (params.size === 0) {
      search();
    } else {
      setInitial(false);
      setStateByUrlParams();
    }
  }, []);

  return (
    <>
      <div className="flex flex-row justify-between laptop:flex-col">
        <div className="">
          <h1 className="text-3xl desktop:text-5xl font-bold mb-5">
            {t('newslistpage/pagetitle')}
          </h1>
        </div>

        <div className="laptop:hidden text-right">
          <button
            type="button"
            className="btn-default-text"
            onClick={() => setShowMobileFilter(!showMobileFilter)}
          >
            <span className="material-symbols-outlined" aria-hidden="true">
              {!showMobileFilter && <>filter_list</>}
              {showMobileFilter && <>close</>}
            </span>
            {selectedCategories.length > 0 && (
              <span>({selectedCategories.length}) </span>
            )}
            {t('newslistpage/filterbutton')}
          </button>
        </div>
      </div>

      {data.newsCategoryFacets.length > 0 && (
        <ul
          className={`laptop:flex flex-wrap gap-2 mb-10 ${
            showMobileFilter ? 'flex' : 'hidden laptop:block'
          }`}
        >
          <li>
            <button
              type="button"
              className={`pill ${
                selectedCategories.length === 0 ? 'selected' : ''
              }`}
              onClick={() => setSelectedCategories([])}
            >
              Alle
            </button>
          </li>
          {data.newsCategoryFacets.map((category: any) => {
            return (
              <li key={uuidv4()}>
                <button
                  type="button"
                  onClick={() => {
                    handleToggleCategory(category.filterId);
                  }}
                  className={`pill ${
                    selectedCategories.includes(category.filterId)
                      ? 'selected'
                      : ''
                  }`}
                >
                  {category.filterName}
                </button>
              </li>
            );
          })}
        </ul>
      )}

      {data.news.length > 0 && (
        <div className="flex flex-col tablet:flex-row tablet:flex-wrap -mx-3.5">
          {data.news.map((item: any) => {
            return <NewsListCard article={item} key={uuidv4()} />;
          })}
        </div>
      )}

      {data.news.length > 0 && (
        <div className="w-full text-center py-10">
          <p>
            {t('newslistpage/show/part1')} {data.news.length}{' '}
            {t('newslistpage/show/part2')}
          </p>
          {data.news.length !== data.totalMatching && (
            <button
              onClick={() => {
                loadMore();
              }}
              type="button"
              className="btn-default btn-default-black"
            >
              {t('newslistpage/more')}
            </button>
          )}
        </div>
      )}

      {data.news.length === 0 && initial && (
        <div className="flex items-center justify-center w-full h-[200px] laptop:h-[250px] opacity-50 bg-white-warm">
          <Loader />
        </div>
      )}
    </>
  );
};
export default NewsListPage;
