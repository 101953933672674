import React from 'react';

const Loader: React.FC = () => (
  <div
    className="absolute flex items-center justify-center top-8"
    role="status"
    aria-live="polite"
  >
    <div className="relative">
      <div className="w-8 h-8 border-4 rounded-full border-purple animate-spin border-t-transparent"></div>
      <div className="absolute inset-0 border-4 rounded-full opacity-25 border-gray-80"></div>
    </div>
  </div>
);

export default Loader;
