import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchFacet } from '../../Search/Scripts/Models/SearchFacet';

interface Props {
  filterType: string;
  categoryName?: string;
  filters: FilterPill[];
  handleFilterChange: (filterType: string, filterId: string) => void;
  modalView: boolean;
}

type FilterPill = SearchFacet & { active: boolean };
interface FilterPillWidth extends FilterPill {
  width: number;
}

const FilterButtonGroup: React.FC<Props> = ({
  filterType,
  categoryName = '',
  filters,
  handleFilterChange,
  modalView,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [isLaptop, setIsLaptop] = useState(window.innerWidth >= 1024);
  const [buttonWidths, setButtonWidths] = useState<FilterPillWidth[]>([]);
  const [visibleButtonsCount, setVisibleButtonsCount] = useState<number>(0);
  const [hasOverflow, setHasOverflow] = useState(false);
  const [hiddenActiveFilters, setHiddenActiveFilters] = useState(0);
  const [showMore, setShowMore] = useState(false);

  const showMoreLabel = showMore
    ? `${t('productlistpage/showless')}`
    : hiddenActiveFilters > 0
    ? `${t('productlistpage/showmore')} (${hiddenActiveFilters} ${t(
        'productlistpage/selected'
      )})`
    : `${t('productlistpage/showmore')}`;

  useEffect(() => {
    const handleResize = () => {
      setIsLaptop(window.innerWidth >= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Measuring button widths to calculate when to show the toggle expand-button, also when resizing browser window
  useEffect(() => {
    if (!modalView) {
      const getButtonWidths = async () => {
        const widths = await Promise.all(
          filters.map((filter) => measureButtonWidth(isLaptop, filter))
        );
        setButtonWidths(widths);
      };
      getButtonWidths();
    }
  }, [filters, modalView, isLaptop]);

  const measureButtonWidth = useCallback(
    (isLaptop: boolean, filter: FilterPill): Promise<FilterPillWidth> => {
      const btnHeight = isLaptop ? '36px' : '32px';
      const btnPadding = isLaptop ? '0 22px' : '0 16px';
      const bntFontSize = isLaptop ? '16px' : '14px';

      return new Promise((resolve) => {
        const span = document.createElement('span');
        span.style.position = 'absolute';
        span.style.visibility = 'hidden';
        span.style.width = 'auto';
        span.style.height = btnHeight;
        span.style.padding = btnPadding;
        span.style.fontFamily = 'Poppins, Arial, sans-serif';
        span.style.fontSize = bntFontSize;
        span.style.border = '1px solid';
        span.style.whiteSpace = 'nowrap';
        span.textContent = filter.filterName;
        document.body.appendChild(span);

        setTimeout(() => {
          const width = span.getBoundingClientRect().width;
          document.body.removeChild(span);
          resolve({ ...filter, width });
        }, 0);
      });
    },
    []
  );

  useEffect(() => {
    if (modalView) {
      setVisibleButtonsCount(filters.length);
    } else {
      const findVisibleButtons = () => {
        if (!containerRef.current || buttonWidths.length === 0) return;
        const container = containerRef.current;
        const containerWidth = container.getBoundingClientRect().width;
        const allFiltersBtnWidth =
          document.getElementById('all-filters')?.getBoundingClientRect()
            .width ?? (isLaptop ? 102 : 84); // Width of the Show all filters-button
        const toggleBtnWidth =
          document.getElementById('toggle-expand')?.getBoundingClientRect()
            .width ?? 118; // Width of the toggle expand button
        const gapSize = 8; // 8px gap between filter buttons
        let totalWidthFirstRow = 0;
        let totalWidthSecondRow = 0;
        let rowIndex = 1;
        let visibleButtons = 0;
        let overflowsContainer = false;
        let hiddenActiveBtn = 0;

        // Calculate widths for each row
        for (const filter of buttonWidths) {
          const requiredWidth = filter.width + gapSize;
          if (rowIndex === 1) {
            // Calculates first row
            if (
              totalWidthFirstRow + requiredWidth + allFiltersBtnWidth <=
              containerWidth
            ) {
              totalWidthFirstRow += requiredWidth;
              visibleButtons++;
            } else {
              // Button doesn't fit in the first row, increase rowIndex and start calculating totalWidthSecondRow
              rowIndex++;
              totalWidthSecondRow = requiredWidth;
              visibleButtons++;
            }
          } else if (rowIndex === 2) {
            if (
              totalWidthSecondRow + requiredWidth + toggleBtnWidth <=
              containerWidth
            ) {
              totalWidthSecondRow += requiredWidth;
              visibleButtons++;
            } else {
              // Button doesn't fit in the second row, increase rowIndex
              rowIndex++;
              overflowsContainer = true;
              if (filter.active) hiddenActiveBtn++;
            }
          } else {
            // Button doesn't fit in the first or second rows, and is overflowing the container
            overflowsContainer = true;
            if (filter.active) hiddenActiveBtn++;
          }
        }
        setHasOverflow(overflowsContainer);
        setVisibleButtonsCount(visibleButtons);
        setHiddenActiveFilters(hiddenActiveBtn);
      };

      window.addEventListener('resize', findVisibleButtons);
      findVisibleButtons();

      return () => {
        window.removeEventListener('resize', findVisibleButtons);
      };
    }
  }, [buttonWidths, modalView, isLaptop, showMore]);

  return (
    <>
      {filters !== null && filters.length > 0 && (
        <div className="flex flex-col items-start self-stretch justify-end gap-2">
          <p className="u-paragraph-m">
            {filterType === 'season'
              ? `${t('productlistpage/filters/seasontitle')}`
              : `${t(
                  'productlistpage/filters/categoriestitlestart'
                )} ${categoryName} ${t(
                  'productlistpage/filters/categoriestitleend'
                )}`}
          </p>
          <div
            ref={containerRef}
            className={`relative self-stretch justify-start items-end gap-2 flex flex-wrap overflow-hidden ${
              !showMore && !modalView
                ? 'max-h-[72px] laptop:max-h-20'
                : 'max-h-fit'
            }`}
          >
            <button
              onClick={() => {
                handleFilterChange(filterType, '');
              }}
              id={'all-filters'}
              className={`pill ${
                filters.every((f) => !f.active) ? 'selected' : ''
              }`}
            >
              {t('productlistpage/filters/showall')}
            </button>

            {filters
              .slice(0, showMore ? filters.length : visibleButtonsCount)
              .map((filter) => {
                return (
                  <button
                    key={filter.filterId}
                    onClick={() => {
                      handleFilterChange(filterType, filter.filterId);
                    }}
                    className={`pill ${filter.active ? 'selected' : ''}
              `}
                  >
                    {filter.filterName}
                  </button>
                );
              })}
            {hasOverflow && (
              <button
                id={'toggle-expand'}
                onClick={() => {
                  setShowMore(!showMore);
                }}
                className={`btn-default min-w-[116px] h-8 laptop:h-9 flex items-center justify-center py-1.5 px-3 gap-2 u-paragraph-m `}
              >
                <span>{showMoreLabel}</span>
                <span className="material-symbols-outlined" aria-hidden="true">
                  {showMore ? 'expand_less' : 'expand_more'}
                </span>
              </button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default FilterButtonGroup;
