import * as React from 'react';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Loader from '@/Global/Scripts/Loader/Loader';

interface Article {
  article: {
    ingress: string;
    pageTitle: string;
    url: string | undefined;
    publishDateString: {
      date: string;
      text: string;
    };
    image: {
      imageUrl: string;
      isImageshop: boolean;
      useFocalpoint: boolean;
      imageAltText: string;
    };
  };
}

interface Images {
  dimensionId: string;
  imageUrl: string;
}

const NewsListCard: FC<Article> = (props) => {
  const [images, setImages] = React.useState<Images[]>([]);
  const [imageLoading, setImageLoading] = React.useState(true);

  const getAllImageSizes = async () => {
    const url = '/api/images/generatescaledimageurls';

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ImageUrl: props.article.image.imageUrl,
          IsImageShop: props.article.image.isImageshop,
          UseFocalPoint: true,
          Dimensions: [
            {
              DimensionId: '1680',
              width: 531,
              height: 250,
            },
            {
              DimensionId: '1024',
              width: 312,
              height: 250,
            },
            {
              DimensionId: '768',
              width: 290,
              height: 200,
            },
            {
              DimensionId: '640',
              width: 290,
              height: 200,
            },
            {
              DimensionId: '319', // Smallest mobile
              width: 607,
              height: 200,
            },
            {
              DimensionId: 'default',
              width: 607,
              height: 200,
            },
          ],
        }),
      });

      const data = await response.json();

      setImages(data);
    } catch (error) {
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (props.article.image !== null) {
      getAllImageSizes();
    }
  }, []);

  return (
    <div className="w-full tablet:w-1/2 laptop:w-1/3">
      <div className="p-3.5">
        <div className="h-full cursor-pointer relative group">
          {images.length > 0 && (
            <picture>
              {images.map((el) => {
                if (el.dimensionId === 'default') {
                  return (
                    <img
                      src={el.imageUrl}
                      alt={props.article.image?.imageAltText}
                      loading="lazy"
                      className={` object-cover rounded-md transition-opacity ${
                        imageLoading
                          ? 'opacity-0 h-0'
                          : 'opacity-100 h-[200px] laptop:h-[250px]'
                      }`}
                      key={uuidv4()}
                      onLoad={() => setImageLoading(false)}
                    />
                  );
                } else {
                  return (
                    <source
                      media={`(min-width: ${el.dimensionId}px)`}
                      srcSet={el.imageUrl}
                      key={uuidv4()}
                    />
                  );
                }
              })}
            </picture>
          )}

          {imageLoading && images.length > 0 && (
            <div className="flex items-center justify-center w-full h-[200px] laptop:h-[250px] opacity-50 bg-white-warm">
              <Loader />
            </div>
          )}

          {images.length === 0 && (
            <div className="h-[200px] laptop:h-[250px] rounded-md w-full bg-white-warm flex items-center justify-center group-hover:shadow-[-7px_7px_0px_0px_#C9C6C2] group-active:shadow-[-3px_3px_0px_0px_#C9C6C2] focus-within:outline focus-within:outline-1 transition-shadow">
              <div className="w-1/2 h-auto">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 118 40"
                  className="fill-white-warm-dark"
                >
                  <path d="M20.6532 0H26.6638C27.976 4.95238 25.2246 9.26984 18.7484 9.26984C17.9019 9.26984 17.1823 9.22751 16.5474 9.14286V23.4921C16.5474 25.2698 17.2246 26.2011 18.9601 26.2011C20.1453 26.2011 20.9495 25.8624 22.0077 25.2698V30.6878C20.9918 31.2381 19.595 31.5767 17.8596 31.5767C11.1294 31.5767 10.4098 26.1587 10.4098 23.4921V7.78836C9.77489 7.66138 9.09764 7.61905 8.29341 7.61905C6.38865 7.61905 5.33045 9.3545 6.38865 11.5556H0.378066C-1.10342 6.81482 1.81722 2.32804 8.29341 2.32804C12.9495 2.32804 15.1506 3.97884 18.7484 3.97884C20.9918 3.97884 21.5421 2.49735 20.6532 0ZM25.9019 9.77778H31.7008V13.2487C32.8013 10.6667 34.8754 9.14286 37.5844 9.14286C41.14 9.14286 43.4257 11.7672 43.4257 15.7037C43.4257 17.3968 43.0024 19.1323 42.3252 20.5714H36.3569C37.1188 19.2169 37.5421 17.9894 37.5421 16.8466C37.5421 15.1958 36.6532 14.1376 35.2564 14.1376C33.1823 14.1376 31.7008 16.6349 31.7008 20.4868V30.9418H25.9019V9.77778ZM45.6268 39.3651V34.4974C46.7273 34.8783 47.7008 35.09 48.5474 35.09C50.9601 35.09 52.1453 33.5661 52.8225 32.0847L44.2299 9.77778H50.3252L55.3622 25.1852L59.976 9.77778H65.9865L58.2828 32.5079C57.394 35.0899 55.3622 40 49.1823 40C48.0818 40 46.8543 39.8307 45.6268 39.3651Z" />
                  <path d="M87.9548 22.9418C87.9548 27.9788 84.3992 31.5767 78.0924 31.5767C75.7643 31.5767 73.5633 30.8995 71.6585 29.6296L70.8119 30.9418H64.6744C65.8172 29.3333 66.8331 27.5556 67.6797 25.6508C66.4945 23.9577 65.6056 21.9259 64.9707 19.6402L69.1188 17.8201C69.3305 18.5397 69.5421 19.2593 69.7961 19.8942C70.8543 16.4233 71.4892 12.8677 71.8278 9.77779H78.6003C81.4363 14.0529 87.9548 17.3545 87.9548 22.9418ZM78.0924 26.6667C80.886 26.6667 82.1135 25.1852 82.1135 22.9418C82.1135 19.7249 79.1929 18.6667 76.2299 15.3228C75.6373 18.6667 74.7908 22.1799 73.6479 25.2275C74.9601 26.1587 76.4416 26.6667 78.0924 26.6667Z" />
                  <path d="M90.7484 21.3757L91.722 9.77778H97.0976L96.1241 21.3757C95.7855 25.3968 97.0976 26.6243 99.3833 26.6243C100.992 26.6243 102.685 25.8201 104.251 24.5079C102.346 19.9788 101.542 14.0106 101.542 8.88889C101.542 3.76719 104.293 0.211639 109.373 0.211639C114.96 0.211639 117.288 4.48677 117.288 9.39682C117.288 14.3069 114.706 20.5714 110.727 25.2275C111.531 26.0741 112.505 26.6243 113.69 26.6243C114.791 26.6243 116.018 26.2434 116.949 25.6931V30.6032C116.018 31.1534 114.494 31.5767 112.463 31.5767C110.177 31.5767 108.315 30.5185 106.833 28.8254C104.463 30.5185 101.838 31.5767 99.1294 31.5767C93.2458 31.5767 90.2405 27.3439 90.7484 21.3757ZM91.468 3.64021C91.468 1.69312 92.9495 0.211639 94.8966 0.211639C96.8437 0.211639 98.3251 1.69312 98.3251 3.64021C98.3251 5.5873 96.8437 7.06878 94.8966 7.06878C92.9495 7.06878 91.468 5.5873 91.468 3.64021ZM108.272 19.5979C110.092 16.5079 111.32 12.7831 111.32 9.43915C111.32 6.51852 110.6 5.16402 109.373 5.16402C108.315 5.16402 107.299 6.01058 107.299 8.88889C107.299 11.3862 107.426 15.8307 108.272 19.5979Z" />
                </svg>
              </div>
            </div>
          )}

          <p className="my-2 text-sm">
            {props.article.publishDateString.text}:{' '}
            {props.article.publishDateString.date}
          </p>
          <h3 className="text-2xl font-bold mb-2 group-hover:underline">
            {props.article.pageTitle}
          </h3>
          {props.article.ingress !== '' && (
            <p className="line-clamp-2 text-base">{props.article.ingress}</p>
          )}

          <a
            href={props.article.url}
            title={props.article.pageTitle}
            className="absolute inset-0 cursor-pointer"
          >
            <span className="sr-only">{props.article.pageTitle}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default NewsListCard;
