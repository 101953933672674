import * as React from 'react';
import { FC } from 'react';

interface productList {
  productListType: string;
  cssClass: string;
}

interface iconobject {
  path: string;
  viewbox: string;
}

const MainMenuIconProductlist: FC<productList> = (props) => {
  const [icon, setIcon] = React.useState<iconobject>({ path: '', viewbox: '' });

  React.useEffect(() => {
    switch (props.productListType) {
      case '1':
        setIcon({
          path: '/svg/sprites.svg#icon-vacation',
          viewbox: '0 0 24 24',
        });
        break;
      case '2':
        setIcon({
          path: '/svg/sprites.svg#icon-places-to-eat',
          viewbox: '0 0 15 20',
        });
        break;
      case '3':
        setIcon({
          path: '/svg/sprites.svg#icon-activities',
          viewbox: '0 0 24 24',
        });
        break;
      case '4':
        setIcon({
          path: '/svg/sprites.svg#icon-stores',
          viewbox: '0 0 24 24',
        });
        break;
      case '5':
        setIcon({
          path: '/svg/sprites.svg#icon-service',
          viewbox: '0 0 24 24',
        });
        break;
      default:
        setIcon({
          path: '/svg/sprites.svg#icon-calendar',
          viewbox: '0 0 18 24',
        });
        break;
    }
  }, []);

  return (
    <>
      {icon.viewbox !== '' && icon.path !== '' && (
        <svg viewBox={icon.viewbox} className={props.cssClass}>
          <use xlinkHref={icon.path}></use>
        </svg>
      )}
    </>
  );
};
export default MainMenuIconProductlist;
