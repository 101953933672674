import * as React from 'react';
import { useTranslation } from 'react-i18next';
import EventCard from './CalendarSearchPageEventCard';
import Heading from './CalrendarSearchPageHeading';
import Loader from './Loader';

interface Page {
  pageTitle: string;
  url: string;
  ingress: string;
  pageType: string;
  timeOfEvent: string;
  image: {
    imageUrl: string;
    isImageshop: boolean;
    imageAltText: string;
  };
}
interface ModelCalendar {
  eventCategoryFacets: [
    {
      filterKey: string;
      filterName: string;
      filterId: string;
      parentId: string;
      count: number;
    }
  ];
  areaCategoryFacets: [
    {
      filterKey: string;
      filterName: string;
      filterId: string;
      parentId: string;
      count: number;
    }
  ];
  totalMatching: number;
  success: boolean;
  lastPage: boolean;
  page: number;
  pageSize: number;
  pageCount: number;
  pages: Page[] | [];
}

const CalendarSearchPage = () => {
  const { t } = useTranslation();

  const [inital, setInitial] = React.useState(true);
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [startDate, setStartDate] = React.useState<string>('');
  const [endDate, setEndDate] = React.useState<string>('');
  const [eventCategories, setEventCategories] = React.useState<number[]>([]);
  const [areaCategories, setAreaCategories] = React.useState<number[]>([]);

  const [calendar, setCalendar] = React.useState<ModelCalendar>();
  const [calendarPages, setCalendarPages] = React.useState<Page[]>([]);

  const [pageSize, setPageSize] = React.useState<number>(12); // Default value 12
  const [showMobileFilter, setShowMobileFilter] = React.useState(false);

  // Update state for eventCategories
  const handleEventCategories = (id: string) => {
    setPageSize(12);
    const idAsNumber = parseInt(id, 10);

    // Add to eventCategories
    if (!eventCategories.includes(idAsNumber, 0)) {
      const newArr = [...eventCategories, idAsNumber];
      setEventCategories(newArr);
      return;
    }

    // Remove from eventCategories
    if (eventCategories.includes(idAsNumber, 0)) {
      const newArr = [...eventCategories];
      newArr.filter((value, index, newArr) => {
        if (value === idAsNumber) {
          newArr.splice(index, 1);
          return true;
        }
        return false;
      });
      setEventCategories(newArr);
    }
  };

  // Update state for areaCategories
  const handleAreaCategories = (id: string) => {
    setPageSize(12);
    const idAsNumber = parseInt(id, 10);

    // Add to eventCategories
    if (!areaCategories.includes(idAsNumber, 0)) {
      const newArr = [...areaCategories, idAsNumber];
      setAreaCategories(newArr);
      return;
    }

    // Remove from areaCategories
    if (areaCategories.includes(idAsNumber, 0)) {
      const newArr = [...areaCategories];
      newArr.filter((value, index, newArr) => {
        if (value === idAsNumber) {
          newArr.splice(index, 1);
          return true;
        }
        return false;
      });
      setAreaCategories(newArr);
    }
  };

  // Update state for start date
  const handleStartDate = (date: string) => {
    setPageSize(12);
    setStartDate(date);
  };

  // Update state for end date
  const handleEndDate = (date: string) => {
    setPageSize(12);
    setEndDate(date);
  };

  // Load more
  const handleMore = () => {
    let page: number | undefined;
    page = 1;
    if (typeof calendar !== 'undefined') {
      page = calendar?.page;
    }

    setPageSize((Number(page) + 1) * pageSize);
  };

  // Reset filter
  const handleReset = () => {
    setLoading(true);
    setPageSize(12);
    setEventCategories([]);
    setAreaCategories([]);
    setStartDate('');
    setEndDate('');
    setLoading(false);
  };

  // Format date
  const formatDate = (date: Date) => {
    const d = date.getDate().toString();
    const day = ('0' + d).slice(-2);

    const m = (date.getMonth() + 1).toString();
    const month = ('0' + m).slice(-2);

    const year = date.getFullYear().toString();
    return year + '-' + month + '-' + day;
  };

  // Update URL
  const updateUrl = (parameters: string) => {
    // Update parameter in URL
    const url = new URL(
      location.protocol + '//' + location.host + location.pathname
    );

    history.replaceState({}, '', `${url}?${parameters}`);
  };

  // Set states based on URL parameters
  const setStateByUrlParams = () => {
    const params = new URLSearchParams(document.location.search);
    const start = params.get('StartDate');
    const end = params.get('EndDate');
    const event = params.get('EventCategories');
    const area = params.get('AreaCategories');
    const size = params.get('pagesize');

    // Set start date
    if (start !== '' && start !== null) {
      setStartDate(formatDate(new Date(start)));
    }

    // Set end date
    if (end !== '' && end !== null) {
      setEndDate(formatDate(new Date(end)));
    }

    // Set events categories
    if (typeof event === 'string' && event !== null) {
      setEventCategories(event.split(',').map(Number));
    }

    // Set areas categories
    if (typeof area === 'string' && area !== null) {
      setAreaCategories(area.split(',').map(Number));
    }

    // Set page size
    if (typeof size === 'string' && size !== null) {
      setPageSize(Number(size));
    }
  };

  // Search
  const search = async () => {
    setLoading(true);
    setError(false);
    let start = '';
    if (startDate !== '') {
      start = `StartDate=${startDate}`;
    }

    let end = '';
    if (endDate !== '') {
      end = `&EndDate=${endDate}`;
    }

    let event = '';
    if (eventCategories.length > 0) {
      event = `&EventCategories=${eventCategories}`;
    }

    let area = '';
    if (areaCategories.length > 0) {
      area = `&AreaCategories=${areaCategories}`;
    }

    let pageParam = '';
    if (pageSize !== 12) {
      pageParam = `&pagesize=${pageSize}`;
    }

    const url = `/api/calendar/search?${start}${end}${event}${area}${pageParam}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      setCalendar(data);
      setCalendarPages(data.pages);
      setLoading(false);
      updateUrl(`${start}${end}${event}${area}${pageParam}`);
    } catch (error) {
      setLoading(false);
      setError(true);
      console.log(error);
    }
  };

  React.useEffect(() => {
    if (!inital) {
      search();
    }
  }, [startDate, endDate, eventCategories, areaCategories, pageSize]);

  // Init
  React.useEffect(() => {
    const params = new URLSearchParams(document.location.search);

    if (params.size === 0) {
      search();
      setInitial(false);
    } else {
      setInitial(false);
      setStateByUrlParams();
    }
  }, []);

  return (
    <>
      <form>
        <fieldset className="w-full max-w-[680px] mb-10 flex flex-col tablet:flex-row justify-between gap-5">
          <legend className="sr-only">{t('calendar/dates')}</legend>
          <div className="w-full desktop:w-1/2">
            <label htmlFor="startdate" className="block text-xl mb-4">
              {t('calendar/from')}:
            </label>
            <input
              type="date"
              id="startdate"
              name="startdate"
              onChange={(event) => {
                handleStartDate(event.target.value);
              }}
              value={startDate}
              className="px-4 py-2 border border-black rounded bg-white text-xl placeholder:text-gray-60 text-black w-full"
            />
          </div>

          <div className="w-full desktop:w-1/2">
            <label htmlFor="enddate" className="block text-xl mb-4">
              {t('calendar/to')}:
            </label>
            <input
              type="date"
              id="enddate"
              name="enddate"
              onChange={(event) => {
                handleEndDate(event.target.value);
              }}
              value={endDate}
              className="px-4 py-2 border border-black rounded bg-white text-xl placeholder:text-gray-60 text-black w-full"
            />
          </div>
        </fieldset>

        <div className="block tablet:hidden text-right my-10">
          <button
            type="button"
            className="btn-default-text"
            onClick={() => setShowMobileFilter(!showMobileFilter)}
          >
            <span className="material-symbols-outlined" aria-hidden="true">
              filter_list
            </span>
            {t('calendar/filter')}
          </button>
        </div>

        <div
          className={`${
            showMobileFilter
              ? 'block -mt-10 tablet:mt-0'
              : 'hidden tablet:block'
          }`}
        >
          {typeof calendar !== 'undefined' &&
            calendar.eventCategoryFacets.length > 0 && (
              <fieldset>
                <legend className="mb-2 text-base">
                  {t('calendar/labellookingforevent')}
                </legend>
                <ul className="flex gap-2 mb-10">
                  <li>
                    <button
                      type="button"
                      className={`pill ${
                        eventCategories.length === 0 ? 'selected' : ''
                      }`}
                      onClick={() => {
                        setEventCategories([]);
                      }}
                    >
                      {t('calendar/showall')}
                    </button>
                  </li>
                  {calendar.eventCategoryFacets.map((item) => {
                    return (
                      <li key={item.filterId}>
                        <button
                          type="button"
                          className={`pill ${
                            eventCategories.includes(
                              parseInt(item.filterId, 10)
                            )
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => {
                            handleEventCategories(item.filterId);
                          }}
                        >
                          {item.filterName}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </fieldset>
            )}

          {typeof calendar !== 'undefined' &&
            calendar.areaCategoryFacets.length > 0 && (
              <fieldset>
                <legend className="mb-2 text-base">
                  {t('calendar/labellookingforarea')}
                </legend>
                <ul className="flex gap-2 mb-10">
                  <li>
                    <button
                      type="button"
                      className={`pill ${
                        areaCategories.length === 0 ? 'selected' : ''
                      }`}
                      onClick={() => {
                        setAreaCategories([]);
                      }}
                    >
                      {t('calendar/showall')}
                    </button>
                  </li>
                  {calendar.areaCategoryFacets.map((item) => {
                    return (
                      <li key={item.filterId}>
                        <button
                          type="button"
                          className={`pill ${
                            areaCategories.includes(parseInt(item.filterId, 10))
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => {
                            handleAreaCategories(item.filterId);
                          }}
                        >
                          {item.filterName}
                        </button>
                      </li>
                    );
                  })}
                </ul>
              </fieldset>
            )}
        </div>
      </form>

      {calendar && (
        <div className="flex justify-between mb-6">
          <Heading
            start={startDate}
            end={endDate}
            selectedEventCategories={eventCategories}
            selectedAreaCategories={areaCategories}
            eventCategories={calendar.eventCategoryFacets}
            areaCategories={calendar.areaCategoryFacets}
          />
          {(eventCategories.length > 0 || areaCategories.length > 0) && (
            <div className="shrink-0">
              <button
                type="button"
                onClick={() => {
                  handleReset();
                }}
                className="btn-discreet-gray"
              >
                {t('calendar/resetfilter')}
              </button>
            </div>
          )}
        </div>
      )}

      {typeof calendarPages !== 'undefined' && calendarPages.length > 0 && (
        <>
          <div className="grid grid-cols-1 tablet:grid-cols-2 desktop:grid-cols-3 gap-7 my-10">
            {calendarPages.map((item, index) => {
              return <EventCard event={item} key={index} />;
            })}
          </div>

          {!calendar?.lastPage && (
            <div className="w-full text-center py-10">
              <button
                onClick={handleMore}
                type="button"
                className="btn-default btn-default-black"
              >
                {t('calendar/more')}
              </button>
            </div>
          )}
        </>
      )}

      {typeof calendarPages !== 'undefined' && calendarPages.length === 0 && (
        <div className="p-5 desktop:p-11 text-center bg-yellow rounded-lg">
          <h2 className="text-2xl font-bold mb-2">
            {t('calendar/error/nohitsheader')}
          </h2>
          <p className="text-xl mb-4">{t('calendar/error/nohitstext')}</p>
          {(eventCategories.length > 0 || areaCategories.length > 0) && (
            <button
              type="button"
              onClick={() => {
                handleReset();
              }}
              className="btn-default btn-default-white"
            >
              {t('calendar/resetfilter')}
            </button>
          )}
        </div>
      )}

      {error && (
        <div className=" bg-orange-light p-5 my-10 border-2 border-orange rounded-lg max-w-[680px]">
          <h2 className="text-2xl font-bold">
            {t('calendar/error/errorheader')}
          </h2>
          <p>{t('calendar/error/errortext')}</p>
        </div>
      )}

      {loading && (
        <div className="w-full text-7xl text-gray-60 my-10">
          <Loader />
        </div>
      )}
    </>
  );
};
export default CalendarSearchPage;
