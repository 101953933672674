import * as React from 'react';
import { FC } from 'react';
import { AccordionModel } from './AccordionModel';

const Accordion: FC<AccordionModel> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClick = (e: any) => {
    e.preventDefault();
    open === false ? setOpen(true) : setOpen(false);
  };
  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className="font-sans text-2xl font-light mb-0 bg-white hover:bg-white-warm w-full text-left py-3 flex justify-between items-center"
      >
        {props.title}
        <span
          className={`material-symbols-outlined transition-all duration-300 ${
            open ? 'rotate-180' : ''
          }`}
          aria-hidden="true"
        >
          expand_more
        </span>
      </button>
      <div className={`accordion--body text-base ${open ? '' : 'hidden'}`}>
        <div dangerouslySetInnerHTML={{ __html: props.bodytext }} />
      </div>
    </>
  );
};
export default Accordion;
