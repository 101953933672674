import * as React from 'react';
import { FC } from 'react';

interface ArticleHeroModelsHeading {
  title: string;
  themeColor: string;
}

const ArticleHeroHeading: FC<ArticleHeroModelsHeading> = (props) => {
  const [isVisible, setIsVisible] = React.useState(true);

  // Set initial padding
  const padding = 28;
  const [styleHeading, setStyleHeading] = React.useState(padding);
  const [themeColor, setThemeColor] = React.useState('bg-white');

  const figureRef = React.useRef<HTMLDivElement>(null);

  const checkThemeColor = () => {
    switch (props.themeColor) {
      case '1':
        setThemeColor('bg-orange');
        break;
      case '2':
        setThemeColor('bg-blue');
        break;
      case '3':
        setThemeColor('bg-green');
        break;
      case '4':
        setThemeColor('bg-purple');
        break;
      case '5':
        setThemeColor('bg-yellow');
        break;
      case '6':
        setThemeColor('bg-white');
        break;
      default:
        setThemeColor('bg-white');
    }
  };

  const elementIsVisibleInViewport = (
    el: HTMLElement | null,
    partiallyVisible = false
  ) => {
    if (el !== null) {
      const { top, bottom } = el.getBoundingClientRect();
      const { innerHeight } = window;
      return partiallyVisible
        ? (top > 0 && top < innerHeight) || (bottom > 0 && bottom < innerHeight)
        : top >= 0 && bottom <= innerHeight;
    }
  };

  React.useEffect(() => {
    const handleScroll = () => {
      if (elementIsVisibleInViewport(figureRef.current, true)) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (window.scrollY > padding && isVisible) {
        setStyleHeading(window.scrollY);
      } else {
        setStyleHeading(padding);
      }
    };

    // Add event listener
    window.addEventListener('scroll', handleScroll, true);

    // Remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll, true);
    };
  }, []);

  React.useEffect(() => {
    checkThemeColor();
  }, []);

  return (
    <>
      <h1
        className={`font-bold font-display text-3xl desktop:text-5xl leading-normal text-center w-[calc(100%-48px)] desktop:w-auto desktop:w-max-[984px] absolute bottom-0 left-1/2 -translate-x-1/2 p-4 desktop:p-7 rounded-lg transition-transform duration-75 ease-linear delay-0 ${String(
          themeColor
        )}`}
        style={{ transform: `translate(-50%, -${Number(styleHeading)}px)` }}
      >
        {props.title}
      </h1>
    </>
  );
};
export default ArticleHeroHeading;
