import * as React from 'react';
import { FC } from 'react';
import { CopyUrlButtonModel } from './CopyUrlButtonModel';

const CopyUrlButton: FC<CopyUrlButtonModel> = (props) => {
  const [buttonText, setButtonText] = React.useState('Kopier lenke');
  const [buttonBusy, setButtonBusy] = React.useState(false);

  const handleClick = async (e: any) => {
    e.preventDefault();
    setButtonBusy(true);
    try {
      await navigator.clipboard.writeText(props.url);
      setButtonText('Lenke kopiert');
      setInterval(() => {
        setButtonText('Kopier lenke');
        setButtonBusy(false);
      }, 3000);
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  return (
    <>
      <button
        type="button"
        onClick={handleClick}
        className={`${
          buttonBusy
            ? 'text-xs desktop:text-sm bg-purple-light py-1 px-1.5 desktop:py-1 desktop:px-3 rounded'
            : 'btn-discreet-purple'
        } flex items-center gap-2`}
        disabled={!!buttonBusy}
      >
        <span className="material-symbols-outlined" aria-hidden="true">
          {buttonBusy ? 'new_releases' : 'content_copy'}
        </span>
        <span>{buttonText}</span>
      </button>
    </>
  );
};
export default CopyUrlButton;
