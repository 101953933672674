import * as React from 'react';
import { FC } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Breadcrumbs {
  breadcrumbs: [
    {
      Text: string;
    }
  ];
}
const Breadcrumb: FC<Breadcrumbs> = (props) => {
  return (
    <nav
      aria-label="Breadcrumb"
      className="container px-4 pt-2.5 pb-4 mb-10 desktop:pb-6"
    >
      <ol className="flex flex-wrap items-start gap-0.5">
        {props.breadcrumbs.map((crumb: any, i, { length }) => {
          if (i + 1 === length) {
            return (
              <li key={uuidv4()} className="hidden laptop:block ">
                {crumb.text}
              </li>
            );
          }
          return (
            <li
              key={uuidv4()}
              className="items-center flex last-of-type:hidden laptop:last-of-type:block "
            >
              <a href={crumb.url} title={crumb.text} className="btn-link">
                {crumb.text}
              </a>
              <span className="material-symbols-outlined" aria-hidden="true">
                chevron_right
              </span>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};
export default Breadcrumb;
