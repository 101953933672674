import { Actor as BaseActor } from '@/Actor/Scripts/Models/Actor';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface AlphabeticalActorList {
  letter: string;
  actorListForLetter: Array<{
    actor: BaseActor;
    url: string;
  }>;
}

interface ActorListProps {
  shortcutLetters: string[];
  hasCertifiedActors: boolean;
  alphabeticalActorList: AlphabeticalActorList[];
}

const ActorList: React.FC<ActorListProps> = ({
  shortcutLetters,
  hasCertifiedActors,
  alphabeticalActorList,
}) => {
  const { t } = useTranslation();
  const [showOnlyCertified, setShowOnlyCertified] = useState<boolean>(false);

  return (
    <div className="flex flex-col items-center gap-4 mx-auto laptop:gap-7 max-w-[948px]">
      {/* <!-- Shortcut buttons --> */}
      <div className="grid w-full gap-2 grid-cols-[repeat(auto-fit,minmax(40px,1fr))] laptop:grid-cols-[repeat(auto-fit,minmax(60px,1fr))] max-w-[948px]">
        {shortcutLetters.map((letter) => (
          <a
            href={`#${letter}`}
            key={letter}
            className="box-content content-center w-6 h-6 p-2 text-sm text-center rounded-full bg-purple-light laptop:w-11 laptop:h-7 laptop:text-base hover:bg-white-warm hover:underline active:bg-white-warm-dark active:underline focus-visible:bg-white-warm focus-visible:outline-black focus-visible:underline"
          >
            {letter}
          </a>
        ))}
      </div>
      {/* <!-- Filter for showing only enviroment certified actors --> */}
      {hasCertifiedActors && (
        <div
          className="relative flex flex-col items-start self-start w-auto rounded laptop:self-end group has-[:focus]:outline has-[:focus]:outline-offset-2"
          onClick={() => {
            setShowOnlyCertified(!showOnlyCertified);
          }}
        >
          <div className="absolute top-0.5 left-0.5 z-0 w-5 h-5 bg-white rounded ring-2 ring-black group-hover:ring-purple-dark group-hover:bg-purple-light laptop:w-6 laptop:h-6">
            <input
              type="checkbox"
              className="absolute w-full h-full rounded-md opacity-0 cursor-pointer"
              checked={showOnlyCertified}
            />
            <span
              className={`w-full h-full ${
                showOnlyCertified
                  ? 'absolute -top-0.5 -left-0.5 inline-block material-symbols-outlined !text-[24px] laptop:!text-[28px] !subpixel-antialiased'
                  : 'hidden'
              }`}
              aria-hidden="true"
            >
              check
            </span>
          </div>
          <label className="ml-8 font-semibold cursor-pointer laptop:ml-9 text-sm/6 laptop:text-base/7 group-hover:text-black-light group-hover:underline">
            {t('actorpage/showonlycertified')}
          </label>
        </div>
      )}

      {/* <!-- Actors --> */}
      {alphabeticalActorList.map((actorList) => {
        const allActors = actorList.actorListForLetter.map((item) => ({
          ...item.actor,
          url: item.url,
        }));

        const visibleActors = showOnlyCertified
          ? allActors.filter((actorItem) => actorItem.environmentCertification)
          : allActors;

        return (
          !!visibleActors.length && (
            <div
              key={actorList.letter}
              id={actorList.letter}
              className="flex flex-col items-center w-full gap-4 max-w-[948px]"
            >
              <div className="flex flex-col w-full gap-2 tablet:max-w-[680px]">
                <h2 className="u-display-3">{actorList.letter}</h2>
                {visibleActors
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((actorItem) => {
                    const actor = actorItem;
                    const logo = actor.image;
                    const logoAltText =
                      logo.imageAltText || `Logo, ${actor.name}`;
                    const actorPage = actor.url;

                    return (
                      <div
                        key={actor.name}
                        className="flex flex-col w-full gap-4 p-2 rounded-lg laptop:gap-5 tablet:py-5 tablet:px-4 tablet:p-4 tablet:flex-row bg-white-warm"
                      >
                        {logo.imageUrl ? (
                          <div className="flex self-center w-[100px] h-[100px]">
                            <img
                              src={logo.imageUrl}
                              alt={logoAltText}
                              className="object-contain rounded"
                            />
                          </div>
                        ) : (
                          <div
                            className="content-center self-center text-center rounded bg-white-warm-dark w-[100px] h-[100px]"
                            aria-hidden="true"
                          >
                            <span className="text-5xl font-medium text-white">
                              {actorList.letter}
                            </span>
                          </div>
                        )}
                        <div className="flex flex-col flex-1 w-full gap-2">
                          <div className="flex flex-col justify-between gap-y-4 tablet:flex-row">
                            <h3 className="order-2 tablet:order-1">
                              <a
                                href={actorPage}
                                className="font-semibold u-paragraph-m u-link"
                              >
                                {actor.name}
                              </a>
                            </h3>
                            {actor.environmentCertification && (
                              <p className="flex items-start justify-center order-1 u-paragraph-m text-black-light tablet:order-2">
                                <span
                                  className="material-symbols-outlined !text-[20px] laptop:!text-[24px]"
                                  aria-hidden="true"
                                >
                                  psychiatry
                                </span>
                                {t('actorpage/environmentcertified')}
                              </p>
                            )}
                          </div>
                          <address className="flex flex-col gap-2 text-xl not-italic">
                            {actor.phoneNumber && (
                              <p className="flex items-center gap-1">
                                <span
                                  className="material-symbols-outlined text-gray-50"
                                  aria-hidden="true"
                                >
                                  call
                                </span>
                                <a
                                  href={`#${actor.phoneNumber}`}
                                  className="u-paragraph-m u-link"
                                >
                                  {actor.phoneNumber}
                                </a>
                              </p>
                            )}
                            {actor.emailAddress && (
                              <p className="flex items-center gap-1">
                                <span
                                  className="material-symbols-outlined text-gray-50"
                                  aria-hidden="true"
                                >
                                  mail
                                </span>

                                <a
                                  href={`#${actor.emailAddress}`}
                                  className="u-paragraph-m u-link"
                                >
                                  {actor.emailAddress}
                                </a>
                              </p>
                            )}
                            {actor.address && (
                              <p className="flex items-start gap-1">
                                <span
                                  className="material-symbols-outlined text-gray-50"
                                  aria-hidden="true"
                                >
                                  location_on
                                </span>
                                <span className="u-paragraph-m">
                                  {actor.address}
                                </span>
                              </p>
                            )}
                          </address>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default ActorList;
