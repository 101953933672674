import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Modal WIP - made for product filtering

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  actionButton?: {
    label: string;
    active?: boolean;
    action: () => void;
  };
  children: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  actionButton,
  children,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open');
    }
    // Cleanup and remove 'modal-open' when the modal is closed or component is unmounted
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className={`modal fixed inset-0 z-50 py-8 px-4 transition-opacity duration-300 ease-out ${
        isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
      }`}
    >
      <div
        className={`transition-transform duration-200 ease-out transform bg-white p-4 pt-0 rounded-md max-w-[600px] h-[90vh] flex flex-col justify-between items-start gap-4 overflow-hidden ${
          isOpen ? 'translate-y-0' : 'translate-y-full'
        }`}
      >
        {/* <!-- Children container --> */}
        <div className="relative overflow-y-scroll overscroll-none pt-4">
          <div className="flex flex-col gap-4 ">{children}</div>

          {/* <!-- Gradient at the bottom of the list --> */}
          <div className="sticky bottom-0 w-full h-8 bg-gradient-to-b from-transparent to-white z-10 pointer-events-none"></div>
        </div>

        {/* <!-- Button(s) container --> */}
        <div className="flex flex-row gap-2 w-max">
          <button
            onClick={onClose}
            className="modal-close-button btn-default btn-default-white justify-center items-center"
          >
            {t('shared/close')}
          </button>
          {actionButton && (
            <button
              onClick={actionButton.action}
              disabled={!actionButton.active}
              className={`modal-action-button btn-default ${
                actionButton.active ? 'btn-default-black' : ''
              }`}
            >
              {actionButton.label}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Modal;
