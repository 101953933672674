import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface filters {
  start: string;
  end: string;
  eventCategories: [
    {
      filterKey: string;
      filterName: string;
      filterId: string;
      parentId: string;
      count: number;
    }
  ];
  areaCategories: [
    {
      filterKey: string;
      filterName: string;
      filterId: string;
      parentId: string;
      count: number;
    }
  ];
  selectedEventCategories: number[];
  selectedAreaCategories: number[];
}

const Heading: FC<filters> = (props) => {
  const { t } = useTranslation();

  const [events, setEvents] = React.useState<string[]>([]);
  const [areas, setAreas] = React.useState<string[]>([]);

  const formatDate = (date: string) => {
    const year = date.slice(0, 4);
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);

    return day + '.' + month + '.' + year;
  };

  React.useEffect(() => {
    const titles = props.eventCategories
      .filter((obj) =>
        props.selectedEventCategories.includes(Number(obj.filterId))
      )
      .map((obj) => obj.filterName);
    setEvents(titles);
  }, [events]);

  React.useEffect(() => {
    const titles = props.areaCategories
      .filter((obj) =>
        props.selectedAreaCategories.includes(Number(obj.filterId))
      )
      .map((obj) => obj.filterName);
    setAreas(titles);
  }, [areas]);

  return (
    <>
      {((events && events.length > 0) || (areas && areas.length > 0)) && (
        <h2 className=" text-2xl desktop:text-3xl font-bold">
          {props.start !== '' && props.end !== '' && (
            <span>
              {formatDate(props.start)} - {formatDate(props.end)}
            </span>
          )}

          {props.start !== '' &&
            props.end !== '' &&
            ((events && events.length > 0) || (areas && areas.length > 0)) && (
              <span>,&nbsp;</span>
            )}

          {events && events.length > 0 && (
            <>
              {events.map((title: string, index: number) => {
                return (
                  <span
                    className={
                      index === 0 && (props.start === '' || props.end === '')
                        ? 'capitalize'
                        : 'lowercase'
                    }
                    key={title}
                  >
                    {index !== 0 && <span>,</span>}
                    &nbsp;{title}
                  </span>
                );
              })}
            </>
          )}
          {areas && areas.length > 0 && (
            <>
              {areas.map((title: string) => {
                return <span key={title}>&nbsp;{title},</span>;
              })}
            </>
          )}
        </h2>
      )}

      {events && events.length === 0 && areas && areas.length === 0 && (
        <h2 className=" text-2xl desktop:text-3xl font-bold">
          {t('calendar/events')}
        </h2>
      )}
    </>
  );
};

export default Heading;
