import * as React from 'react';
import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import EventCard from './CalendarSearchPageEventCard';

interface Events {
  events: [
    {
      pageTitle: string;
      image: {
        imageUrl: string;
      };
    }
  ];
}

const CalendarListBlock: FC<Events> = (props) => {
  const { t } = useTranslation();

  const ref = React.useRef<HTMLDivElement | null>(null);
  const [page, setPage] = React.useState<number>(0);

  // Handle button scroll to left
  const handleScrollLeft = () => {
    if (Number(page) > 0) {
      setPage(Number(page) - 1);
    }
  };

  // Handle button scroll to right
  const handleScrollRight = () => {
    if (Number(page) + 2 <= props.events.length) {
      setPage(Number(page) + 1);
    }
  };

  // Handle click paging
  const handleJump = (page: number) => {
    setPage(page);
  };

  // Scroll to element
  const scrollTo = () => {
    if (ref?.current && ref?.current?.firstElementChild) {
      const elementWidth = ref?.current?.firstElementChild.clientWidth;
      ref.current.scrollLeft = elementWidth * page;
    }
  };

  // Button display
  const showNavigation = () => {
    const numberOfEvents = props.events.length as number;
    let classes;
    switch (numberOfEvents) {
      case 1:
        classes = 'hidden';
        break;
      case 2:
        classes = 'tablet:hidden';
        break;
      case 3:
        classes = 'desktop:hidden';
        break;
      default:
        classes = '';
    }
    return classes;
  };

  // Listen to change in state for page
  useEffect(() => {
    scrollTo();
  }, [page]);

  return (
    <div className="relative">
      <button
        onClick={handleScrollLeft}
        className={`btn-slider-control prev z-10 ${showNavigation()}`}
        aria-label={t('productpage/prevslide')}
      >
        <span>&larr;</span>
      </button>
      <button
        onClick={handleScrollRight}
        className={`btn-slider-control next z-10 ${showNavigation()}`}
        aria-label={t('productpage/nextslide')}
      >
        <span>&rarr;</span>
      </button>
      <div
        className="-mx-3 flex snap-x snap-mandatory overflow-x-scroll scroll-smooth scrollbar-hidden py-5 items-stretch"
        ref={ref}
      >
        {props.events.map((item: any, index: React.Key) => {
          return (
            <div
              className="px-3 snap-start scroll-mx-0 shrink-0 w-full tablet:w-1/2 desktop:w-1/3"
              key={index}
            >
              <EventCard event={item} />
            </div>
          );
        })}
      </div>
      <ul
        className={`flex flex-row space-x-3 items-center h-[18px] ${showNavigation()}`}
      >
        {props.events.map((item: any, index: number) => {
          return (
            <li key={index} className="transition-all flex items-center">
              <button
                type="button"
                onClick={() => {
                  handleJump(index);
                }}
                className={` border border-black rounded-full transition-all hover:bg-black-light  ${
                  index === page
                    ? 'bg-black w-[18px] h-[18px]'
                    : 'bg-white w-3.5 h-3.5'
                }`}
              >
                <span className="sr-only">{item.pageTitle}</span>
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default CalendarListBlock;
