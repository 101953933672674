import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface ImageItem {
  url: string;
  alt: string;
  caption: string;
  photographer: string;
  urls: {
    srcSet1680: string;
    srcSet1280: string;
    srcSet1024: string;
    srcSet768: string;
    srcSet640: string;
  };
}

interface ImageCarouselProps {
  images: ImageItem[];
  productTitle: string;
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({
  images,
  productTitle,
}) => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const showControls = images.length > 1;

  const handleTouchStart = useCallback(
    (e: React.TouchEvent<HTMLDivElement>) => {
      e.currentTarget.dataset.touchStartX = e.touches[0].clientX.toString();
    },
    []
  );

  const handleTouchEnd = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    const touchStartX = parseFloat(e.currentTarget.dataset.touchStartX ?? '0');
    const touchEndX = e.changedTouches[0].clientX;
    if (touchEndX < touchStartX - 50) {
      handleNext();
    } else if (touchEndX > touchStartX + 50) {
      handlePrevious();
    }
  }, []);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => {
      return prevIndex === 0 ? images.length - 1 : prevIndex - 1;
    });
  };
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const renderIndicators = () => (
    <div className="flex self-start gap-2">
      {images.map((_, index) => (
        <div
          key={index}
          className="relative w-3 h-3 tablet:w-[15px] tablet:h-[15px] laptop:w-[18px] laptop:h-[18px] flex items-center justify-center"
        >
          <button
            className={`btn-slider-indicator ${
              index === currentIndex ? 'selected' : ''
            }`}
            aria-label={`Slide ${index + 1} ${t('shared/of')} ${images.length}`}
            data-slide-index={index}
            onClick={() => {
              setCurrentIndex(index);
            }}
          ></button>
        </div>
      ))}
    </div>
  );

  return (
    <div className="relative flex flex-col items-start justify-center gap-2 w-full">
      {images.length > 0 && (
        <>
          <figure
            className="relative flex flex-col items-start self-stretch gap-1"
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
          >
            <div className="relative w-full">
              {showControls && (
                <button
                  className="btn-slider-control prev"
                  aria-label={t('productpage/prevslide')}
                  onClick={handlePrevious}
                >
                  <span>←</span>
                </button>
              )}

              <picture className="rounded-lg">
                <source
                  media="(min-width: 1680px)"
                  srcSet={images[currentIndex].urls.srcSet1680}
                />
                <source
                  media="(min-width: 1280px)"
                  srcSet={images[currentIndex].urls.srcSet1280}
                />
                <source
                  media="(min-width: 1024px)"
                  srcSet={images[currentIndex].urls.srcSet1024}
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={images[currentIndex].urls.srcSet768}
                />
                <source
                  media="(min-width: 640px)"
                  srcSet={images[currentIndex].urls.srcSet640}
                />
                <img
                  src={images[currentIndex].urls.srcSet640}
                  alt={`${
                    images[currentIndex].alt ||
                    images[currentIndex].caption ||
                    productTitle
                  }${
                    images[currentIndex].photographer
                      ? `. ${t('shared/photocredit')} ${
                          images[currentIndex].photographer
                        }`
                      : ''
                  }`}
                  className="rounded-lg"
                  loading="lazy"
                />
              </picture>

              {showControls && (
                <button
                  className="btn-slider-control next"
                  aria-label={t('productpage/nextslide')}
                  onClick={handleNext}
                >
                  <span>→</span>
                </button>
              )}
            </div>
            <figcaption className="u-paragraph-m">
              {images[currentIndex].caption ||
                images[currentIndex].alt ||
                productTitle}
            </figcaption>
          </figure>
          {showControls && renderIndicators()}
        </>
      )}
    </div>
  );
};

export default ImageCarousel;
