import * as React from 'react';
import { FC } from 'react';

interface box {
  title: string;
  subtitle: string;
  url: string;
}

const SearcPageEditorBox: FC<box> = (props) => {
  return (
    <div className=" pt-10 pb-[60px] px-5 bg-white-warm border-[11px] border-white-warm-dark rounded-md w-full max-w-[400px] text-center">
      <h3 className="text-3xl font-bold mb-7">{props.title}</h3>
      {props.url !== '' && (
        <p>
          <a
            href={props.url}
            title={props.subtitle}
            className=" text-xl btn-link"
          >
            {props.subtitle}
          </a>
        </p>
      )}
    </div>
  );
};
export default SearcPageEditorBox;
