import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface weatherModel {
  weatherInfo: {
    weatherAtTheSummitIconCode: string;
    windAtTheSummit: string;
    temperatureAtTheSummit: string;
    weatherInTheValleyIconCode: string;
    windInTheValley: string;
    temperatureInTheValley: string;
    openLifts: string;
    liftCount: string;
    openSlopes: string;
    slopeCount: string;
  };
}

const MainMenuWeather: FC<weatherModel> = (props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="mx-auto w-full desktop:max-w-[948px] px-4 desktop:px-0">
        <div className="grid grid-cols-2 desktop:grid-cols-4 gap-3 tablet:gap-7 mb-6 max-w-[592px] desktop:max-w-full mx-auto">
          <div className="flex flex-row gap-5 items-center">
            <div className="w-16">
              <img
                src={`/svg/fnugg/resort-weather-${props.weatherInfo.weatherAtTheSummitIconCode}.svg`}
                className="w-16 h-16"
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <span className="text-base">
                {t('menu/summit')}: {props.weatherInfo.windAtTheSummit} m/s
              </span>
              <span className=" text-2xl font-bold">
                {props.weatherInfo.temperatureAtTheSummit}&deg;C
              </span>
            </div>
          </div>
          <div className=" flex flex-row gap-5 items-center">
            <div className="w-16">
              <img
                src={`/svg/fnugg/resort-weather-${props.weatherInfo.weatherInTheValleyIconCode}.svg`}
                className="w-16 h-16"
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <span className="text-base">
                {t('menu/valley')}: {props.weatherInfo.windInTheValley} m/s
              </span>
              <span className=" text-2xl font-bold">
                {props.weatherInfo.temperatureInTheValley}&deg;C
              </span>
            </div>
          </div>
          <div className="text-base grid desktop:block grid-cols-2 desktop:grid-cols-1 col-span-2 desktop:col-span-1 gap-3 tablet:gap-7 desktop:gap-0">
            <p>
              {t('menu/openlifts')}: {props.weatherInfo.openLifts}/
              {props.weatherInfo.liftCount}
            </p>
            <p>
              {t('menu/openslopes')}: {props.weatherInfo.openSlopes}/
              {props.weatherInfo.slopeCount}
            </p>
          </div>
          <div className="text-base hidden desktop:block">
            <p>
              <a
                href="http://www.yr.no/sted/Norge/Hedmark/Trysil/Innbygda/langtidsvarsel.html"
                title={t('menu/weatherfromyr')}
                target="_blank"
                className="group text-white hover:text-purple active:text-purple-dark flex items-center gap-1"
                rel="noreferrer"
              >
                <span className="underline">{t('menu/weatherfromyr')}</span>
                <span className="material-symbols-outlined" aria-hidden="true">
                  open_in_new
                </span>
              </a>
            </p>
            <p>
              <a
                href="http://www2.nve.no/h/hd/plotreal/H/0311.00006.000/"
                title={t('menu/waterlevel')}
                target="_blank"
                className="group text-white hover:text-purple active:text-purple-dark flex items-center gap-1"
                rel="noreferrer"
              >
                <span className="underline">{t('menu/waterlevel')}</span>
                <span
                  className="material-symbols-outlined text-sm"
                  aria-hidden="true"
                >
                  open_in_new
                </span>
              </a>
            </p>
          </div>
        </div>
        <p className=" text-xs text-gray-70 text-center mb-6">
          {t('menu/footnote1')}{' '}
          <a
            href="https://www.fnugg.no/"
            title="fnugg.no"
            className="underline hover:text-purple acitve:text-purple-dark"
          >
            fnugg
          </a>
          ,&nbsp;{t('menu/footnote2')}
        </p>
      </div>
    </>
  );
};
export default MainMenuWeather;
